import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Typography, DatePicker } from "antd";

import GTMStackedBarGraph from "components/GTMDashboardGraphs/GTMStackedBarGraph";
import {
  useGTMOrdersPerDay,
  useGTMOrdersPerWeek,
  useGTMTotalKitsPerDay,
  useGTMTotalKitsPerWeek,
  useGTMOrdersFulfilledTat,
} from "api/gtmDashboard";
import {
  orderDataKeys,
  orderDataColors,
  orderStackMap,
  orderKitsDataKeys,
  orderKitsDataColors,
  orderKitsStackMap,
  orderTatKeys,
  orderTatColors,
  orderTatStackMap,
} from "./configs";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const GTMOrderShipments = () => {
  const [dayRange, setDayRange] = useState(null);
  const [weekRange, setWeekRange] = useState(null);
  const [kitDayRange, setKitDayRange] = useState(null);
  const [kitWeekRange, setKitWeekRange] = useState(null);
  const [tatRange, setTatRange] = useState(null);

  const ordersPerDayData = useGTMOrdersPerDay({
    startDate: dayRange?.[0],
    endDate: dayRange?.[1],
  });
  const ordersPerWeekData = useGTMOrdersPerWeek({
    startDate: weekRange?.[0],
    endDate: weekRange?.[1],
  });
  const kitsPerDayData = useGTMTotalKitsPerDay({
    startDate: kitDayRange?.[0],
    endDate: kitDayRange?.[1],
  });
  const kitsPerWeekData = useGTMTotalKitsPerWeek({
    startDate: kitWeekRange?.[0],
    endDate: kitWeekRange?.[1],
  });
  const ordersTatData = useGTMOrdersFulfilledTat({
    startDate: tatRange?.[0],
    endDate: tatRange?.[1],
  });

  return (
    <>
      <Title>Orders Shipped (per day)</Title>
      <RangePicker
        onChange={(_, dateStrings) => setDayRange(dateStrings)}
        disabledDate={(day) => new Date(day) < new Date("2024-09-01")} // Don't allow looking before GTM integration
      />
      <GTMStackedBarGraph
        chartData={ordersPerDayData}
        dataKeys={orderDataKeys}
        dataColors={orderDataColors}
        axisKey="date"
        stackMap={orderStackMap}
      />
      <br />
      <Title>Orders Shipped (per week)</Title>
      <RangePicker
        onChange={(_, dateStrings) => setWeekRange(dateStrings)}
        disabledDate={
          (day) =>
            new Date(day) < new Date("2024-09-01") || // Don't allow looking before GTM integration
            (new Date(day).getDay() !== 0 && new Date(day).getDay() !== 6) // Closest to only allowing Sunday - Saturday selections
        }
      />
      <GTMStackedBarGraph
        chartData={ordersPerWeekData}
        dataKeys={orderDataKeys}
        dataColors={orderDataColors}
        axisKey="date"
        stackMap={orderStackMap}
      />
      <br />
      <Title>Total Kits Shipped (per day)</Title>
      <RangePicker
        onChange={(_, dateStrings) => setKitDayRange(dateStrings)}
        disabledDate={(day) => new Date(day) < new Date("2024-09-01")} // Don't allow looking before GTM integration
      />
      <GTMStackedBarGraph
        chartData={kitsPerDayData}
        dataKeys={orderKitsDataKeys}
        dataColors={orderKitsDataColors}
        axisKey="date"
        stackMap={orderKitsStackMap}
      />
      <br />
      <Title>Total Kits Shipped (per week)</Title>
      <RangePicker
        onChange={(_, dateStrings) => setKitWeekRange(dateStrings)}
        disabledDate={
          (day) =>
            new Date(day) < new Date("2024-09-01") || // Don't allow looking before GTM integration
            (new Date(day).getDay() !== 0 && new Date(day).getDay() !== 6) // Closest to only allowing Sunday - Saturday selections
        }
      />
      <GTMStackedBarGraph
        chartData={kitsPerWeekData}
        dataKeys={orderKitsDataKeys}
        dataColors={orderKitsDataColors}
        axisKey="date"
        stackMap={orderKitsStackMap}
      />
      <br />
      <Title>Order Fulfillment TAT (per week)</Title>
      <RangePicker
        onChange={(_, dateStrings) => setTatRange(dateStrings)}
        disabledDate={
          (day) =>
            new Date(day) < new Date("2024-09-01") || // Don't allow looking before GTM integration
            (new Date(day).getDay() !== 0 && new Date(day).getDay() !== 6) // Closest to only allowing Sunday - Saturday selections
        }
      />
      <GTMStackedBarGraph
        chartData={ordersTatData}
        dataKeys={orderTatKeys}
        dataColors={orderTatColors}
        axisKey="shipment_week"
        stackMap={orderTatStackMap}
      />
    </>
  );
};

export default withRouter(GTMOrderShipments);
