import { Button, Form, Radio, Slider } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import WithLoading from "../components/WithLoading";
import { DemoCard, DemoContainer } from "./util";

const DemoLoading = () => {
  const [state, setState] = useState({
    key: 0,
    result: "Complete",
    backend_latency: 1000,
  });
  // WithLoading can take a `query` prop to make loading states for react-query
  // queries simpler.
  const query = useQuery({
    queryKey: ["demo", "with-loading-query", state.key],
    queryFn: () =>
      new Promise((resolve, reject) =>
        setTimeout(
          () => (state.result ? resolve(state.result) : reject()),
          state.backend_latency
        )
      ),
    retry: false,
  });
  return (
    <>
      {/*
         Form to tweak the above async function (Query) and to illustate
         success and error states of WithLoading. The actual WithLoading
         component is just a single line at the bottom of this function.
       */}
      <Form
        onFinish={(values) => setState({ ...values, key: Math.random() })}
        initialValues={state}
      >
        <Form.Item name="backend_latency" label="Simulated backend latency">
          <Slider
            min={0}
            max={5000}
            step={100}
            tipFormatter={(x) => `${x} ms`}
          />
        </Form.Item>
        <Form.Item name="result" label="Result">
          <Radio.Group>
            <Radio value="Complete">Success</Radio>
            <Radio value={false}>Error</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Trigger loading
          </Button>
        </Form.Item>
      </Form>
      {/* The actual WithLoading component */}
      <WithLoading query={query}>{query.data}</WithLoading>
    </>
  );
};

const WithLoadingDemo = () => {
  return (
    <DemoContainer>
      <DemoCard title="WithLoading kitchen sink demo">
        <DemoLoading />
      </DemoCard>
      <DemoCard title="Loading state">
        <WithLoading isLoading loadingMessage="Custom loading message..." />
      </DemoCard>
      <DemoCard title="Error state">
        <WithLoading isError errorMessage="Custom error message" />
      </DemoCard>
    </DemoContainer>
  );
};

export default WithLoadingDemo;
