import _ from "lodash";
import moment from "moment";
import { orderServiceCustomQuery } from "./orderService";

export const useGTMKitsReceivedPerDay = ({ startDate, endDate }) => {
  startDate = _.isEmpty(startDate)
    ? moment().subtract(30, "days").format("YYYY-MM-DD")
    : startDate;
  endDate = _.isNil(endDate) ? "" : endDate;
  return orderServiceCustomQuery({
    model: "kits",
    path: `received_by_gtm?period=day&startDate=${startDate}&endDate=${endDate}`,
    method: "get",
  })();
};

export const useGTMKitsReceivedPerWeek = ({ startDate, endDate }) => {
  startDate = _.isEmpty(startDate) ? "2024-09-01" : startDate;
  endDate = _.isNil(endDate) ? "" : endDate;
  return orderServiceCustomQuery({
    model: "kits",
    path: `received_by_gtm?period=week&startDate=${startDate}&endDate=${endDate}`,
    method: "get",
  })();
};

export const useGTMResultsReceivedPerDay = ({ startDate, endDate }) => {
  startDate = _.isEmpty(startDate)
    ? moment().subtract(30, "days").format("YYYY-MM-DD")
    : startDate;
  endDate = _.isNil(endDate) ? "" : endDate;
  return orderServiceCustomQuery({
    model: "vessel-aliquots",
    path: `gtm_results?period=day&startDate=${startDate}&endDate=${endDate}`,
    method: "get",
  })();
};

export const useGTMResultsReceivedPerWeek = ({ startDate, endDate }) => {
  startDate = _.isEmpty(startDate) ? "2024-09-01" : startDate;
  endDate = _.isNil(endDate) ? "" : endDate;
  return orderServiceCustomQuery({
    model: "vessel-aliquots",
    path: `gtm_results?period=week&startDate=${startDate}&endDate=${endDate}`,
    method: "get",
  })();
};

export const useGTMResultsTATPerWeek = ({ startDate, endDate }) => {
  startDate = _.isEmpty(startDate) ? "2024-09-01" : startDate;
  endDate = _.isNil(endDate) ? "" : endDate;
  return orderServiceCustomQuery({
    model: "vessel-aliquots",
    path: `gtm_results_tat_per_week?startDate=${startDate}&endDate=${endDate}`,
    method: "get",
  })();
};

export const useGTMOrdersPerDay = ({ startDate, endDate }) => {
  startDate = _.isEmpty(startDate)
    ? moment().subtract(30, "days").format("YYYY-MM-DD")
    : startDate;
  endDate = _.isNil(endDate) ? "" : endDate;
  return orderServiceCustomQuery({
    model: "orders",
    path: `gtm_orders?period=day&startDate=${startDate}&endDate=${endDate}`,
    method: "get",
  })();
};

export const useGTMOrdersPerWeek = ({ startDate, endDate }) => {
  startDate = _.isEmpty(startDate) ? "2024-09-01" : startDate;
  endDate = _.isNil(endDate) ? "" : endDate;
  return orderServiceCustomQuery({
    model: "orders",
    path: `gtm_orders?period=week&startDate=${startDate}&endDate=${endDate}`,
    method: "get",
  })();
};

export const useGTMTotalKitsPerDay = ({ startDate, endDate }) => {
  startDate = _.isEmpty(startDate)
    ? moment().subtract(30, "days").format("YYYY-MM-DD")
    : startDate;
  endDate = _.isNil(endDate) ? "" : endDate;
  return orderServiceCustomQuery({
    model: "orders",
    path: `gtm_total_kits?period=day&startDate=${startDate}&endDate=${endDate}`,
    method: "get",
  })();
};

export const useGTMTotalKitsPerWeek = ({ startDate, endDate }) => {
  startDate = _.isEmpty(startDate) ? "2024-09-01" : startDate;
  endDate = _.isNil(endDate) ? "" : endDate;
  return orderServiceCustomQuery({
    model: "orders",
    path: `gtm_total_kits?period=week&startDate=${startDate}&endDate=${endDate}`,
    method: "get",
  })();
};

export const useGTMOrdersFulfilledTat = ({ startDate, endDate }) => {
  startDate = _.isEmpty(startDate) ? "2024-09-01" : startDate;
  endDate = _.isNil(endDate) ? "" : endDate;
  return orderServiceCustomQuery({
    model: "orders",
    path: `gtm_tat?startDate=${startDate}&endDate=${endDate}`,
    method: "get",
  })();
};
