import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function GTMStackedBarGraph({
  chartData,
  dataKeys,
  dataColors,
  axisKey,
  stackMap = {},
}) {
  return (
    <>
      <ResponsiveContainer width="100%" height="100%" maxHeight="800px">
        <BarChart
          isAnimationActive={false}
          width={"500px"}
          height={"300px"}
          data={chartData.data?.rows}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 35,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={axisKey} tick={{ angle: -45, textAnchor: "end" }} />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" align="center" />
          {dataKeys.map((dataKey) => (
            <Bar
              dataKey={dataKey}
              stackId={stackMap[dataKey] ?? "a"}
              fill={dataColors[dataKey]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
