import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Typography, DatePicker, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import GTMStackedBarGraph from "components/GTMDashboardGraphs/GTMStackedBarGraph";
import {
  resultsDataKeys,
  resultsDataColors,
  resultsStackMap,
  restultTatDataKeys,
  restultTatDataColors,
  restultTatStackMap,
} from "./configs";
import {
  useGTMResultsReceivedPerDay,
  useGTMResultsReceivedPerWeek,
  useGTMResultsTATPerWeek,
} from "api/gtmDashboard";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const GTMResultsReceived = () => {
  const [dayRange, setDayRange] = useState(null);
  const [weekRange, setWeekRange] = useState(null);
  const [tatRange, setTatRange] = useState(null);

  const perDayData = useGTMResultsReceivedPerDay({
    startDate: dayRange?.[0],
    endDate: dayRange?.[1],
  });
  const perWeekData = useGTMResultsReceivedPerWeek({
    startDate: weekRange?.[0],
    endDate: weekRange?.[1],
  });
  const tatData = useGTMResultsTATPerWeek({
    startDate: tatRange?.[0],
    endDate: tatRange?.[1],
  });

  return (
    <>
      <Title>Total Results (per day)</Title>
      <RangePicker
        onChange={(_, dateStrings) => setDayRange(dateStrings)}
        disabledDate={(day) => new Date(day) < new Date("2024-09-01")} // Don't allow looking before GTM integration
      />
      <GTMStackedBarGraph
        chartData={perDayData}
        dataKeys={resultsDataKeys}
        dataColors={resultsDataColors}
        axisKey="concentrated_date"
        stackMap={resultsStackMap}
      />
      <br />
      <Title>Total Results (per week)</Title>
      <RangePicker
        onChange={(_, dateStrings) => setWeekRange(dateStrings)}
        disabledDate={
          (day) =>
            new Date(day) < new Date("2024-09-01") || // Don't allow looking before GTM integration
            (new Date(day).getDay() !== 0 && new Date(day).getDay() !== 6) // Closest to only allowing Sunday - Saturday selections
        }
      />
      <GTMStackedBarGraph
        chartData={perWeekData}
        dataKeys={resultsDataKeys}
        dataColors={resultsDataColors}
        axisKey="concentrated_date"
        stackMap={resultsStackMap}
      />
      <br />
      <Title>
        Results TAT (per week)
        <Tooltip title="Grouped by week of kit receipt. TAT calculated from day of receipt to date concentrated.">
          <QuestionCircleOutlined
            className="pl-1"
            style={{ "font-size": "16px", verticalAlign: "top" }}
          />
        </Tooltip>
      </Title>
      <RangePicker
        onChange={(_, dateStrings) => setTatRange(dateStrings)}
        disabledDate={
          (day) =>
            new Date(day) < new Date("2024-09-01") || // Don't allow looking before GTM integration
            (new Date(day).getDay() !== 0 && new Date(day).getDay() !== 6) // Closest to only allowing Sunday - Saturday selections
        }
      />
      <GTMStackedBarGraph
        chartData={tatData}
        dataKeys={restultTatDataKeys}
        dataColors={restultTatDataColors}
        axisKey="week_of_receipt"
        stackMap={restultTatStackMap}
      />
    </>
  );
};

export default withRouter(GTMResultsReceived);
