module.exports = {
  kitsDataKeys: ["testing_kits", "bn_kits"],
  kitsDataColors: {
    testing_kits: "#004D8C",
    bn_kits: "#0086D9",
  },

  orderDataKeys: [
    "requested_testing_orders",
    "requested_bn_orders",
    "actual_testing_orders",
    "actual_bn_orders",
  ],
  orderDataColors: {
    requested_testing_orders: "#004D8C",
    requested_bn_orders: "#0086D9",
    actual_testing_orders: "#026643",
    actual_bn_orders: "#0EB67C",
  },
  orderStackMap: {
    requested_testing_orders: "a",
    requested_bn_orders: "a",
    actual_testing_orders: "b",
    actual_bn_orders: "b",
  },

  orderKitsDataKeys: [
    "requested_testing_kits",
    "requested_bn_kits",
    "actual_testing_kits",
    "actual_bn_kits",
  ],
  orderKitsDataColors: {
    requested_testing_kits: "#004D8C",
    requested_bn_kits: "#0086D9",
    actual_testing_kits: "#026643",
    actual_bn_kits: "#0EB67C",
  },
  orderKitsStackMap: {
    requested_testing_kits: "a",
    requested_bn_kits: "a",
    actual_testing_kits: "b",
    actual_bn_kits: "b",
  },

  orderTatKeys: [
    "normal_on_time",
    "next_day_on_time",
    "same_day_on_time",
    "normal_1_late",
    "next_day_1_late",
    "same_day_1_late",
    "normal_2_late",
    "next_day_2_late",
    "same_day_2_late",
    "normal_3_late",
    "next_day_3_late",
    "same_day_3_late",
  ],
  orderTatColors: {
    normal_on_time: "#004D8C",
    next_day_on_time: "#0068B3",
    same_day_on_time: "#0086D9",
    normal_1_late: "#84A63A",
    next_day_1_late: "#A9CA3F",
    same_day_1_late: "#C0DB6A",
    normal_2_late: "#B38220",
    next_day_2_late: "#D9A732",
    same_day_2_late: "#FFCD47",
    normal_3_late: "#8C2014",
    next_day_3_late: "#B33522",
    same_day_3_late: "#D94F34",
  },
  orderTatStackMap: {
    normal_on_time: "a",
    next_day_on_time: "a",
    same_day_on_time: "a",
    normal_1_late: "b",
    next_day_1_late: "b",
    same_day_1_late: "b",
    normal_2_late: "c",
    next_day_2_late: "c",
    same_day_2_late: "c",
    normal_3_late: "d",
    next_day_3_late: "d",
    same_day_3_late: "d",
  },

  resultsDataKeys: [
    "testing_pcr_results",
    "bn_pcr_results",
    "testing_lcms_results",
    "bn_lcms_results",
  ],
  resultsDataColors: {
    testing_pcr_results: "#004D8C",
    bn_pcr_results: "#0086D9",
    testing_lcms_results: "#026643",
    bn_lcms_results: "#0EB67C",
  },
  resultsStackMap: {
    bn_pcr_results: "a",
    testing_pcr_results: "a",
    bn_lcms_results: "b",
    testing_lcms_results: "b",
  },

  restultTatDataKeys: [
    "pcr_lte_2",
    "pcr_3",
    "pcr_4",
    "pcr_gte_5",
    "lcms_lte_7",
    "lcms_8",
    "lcms_9",
    "lcms_gte_10",
  ],
  restultTatDataColors: {
    pcr_lte_2: "#00A4FF",
    pcr_3: "#0086D9",
    pcr_4: "#0068B3",
    pcr_gte_5: "#004D8C",
    lcms_lte_7: "#00C783",
    lcms_8: "#0EB67C",
    lcms_9: "#0A9063",
    lcms_gte_10: "#026643",
  },
  restultTatStackMap: {
    pcr_lte_2: "a",
    pcr_3: "a",
    pcr_4: "a",
    pcr_gte_5: "a",
    lcms_lte_7: "b",
    lcms_8: "b",
    lcms_9: "b",
    lcms_gte_10: "b",
  },
};
