import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Typography, DatePicker } from "antd";

import GTMStackedBarGraph from "components/GTMDashboardGraphs/GTMStackedBarGraph";
import {
  useGTMKitsReceivedPerDay,
  useGTMKitsReceivedPerWeek,
} from "api/gtmDashboard";
import { kitsDataKeys, kitsDataColors } from "./configs";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const GTMKitsReceived = () => {
  const [dayRange, setDayRange] = useState(null);
  const [weekRange, setWeekRange] = useState(null);

  const perDayData = useGTMKitsReceivedPerDay({
    startDate: dayRange?.[0],
    endDate: dayRange?.[1],
  });
  const perWeekData = useGTMKitsReceivedPerWeek({
    startDate: weekRange?.[0],
    endDate: weekRange?.[1],
  });

  return (
    <>
      <Title>Kits Received (per day)</Title>
      <RangePicker
        onChange={(_, dateStrings) => setDayRange(dateStrings)}
        disabledDate={(day) => new Date(day) < new Date("2024-09-01")} // Don't allow looking before GTM integration
      />
      <GTMStackedBarGraph
        chartData={perDayData}
        dataKeys={kitsDataKeys}
        dataColors={kitsDataColors}
        axisKey="date_received"
      />
      <Title>Kits Received (per week)</Title>
      <RangePicker
        onChange={(_, dateStrings) => setWeekRange(dateStrings)}
        disabledDate={
          (day) =>
            new Date(day) < new Date("2024-09-01") || // Don't allow looking before GTM integration
            (new Date(day).getDay() !== 0 && new Date(day).getDay() !== 6) // Closest to only allowing Sunday - Saturday selections
        }
      />
      <GTMStackedBarGraph
        chartData={perWeekData}
        dataKeys={kitsDataKeys}
        dataColors={kitsDataColors}
        axisKey="date_received"
      />
    </>
  );
};

export default withRouter(GTMKitsReceived);
